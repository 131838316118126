import { useNavigate } from 'react-router';
import LoadingScreen from '../components/loading-screen';
//
import { useEffect } from 'react';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useAuthContext } from './useAuthContext';
import { useUserContext } from './useUserContext';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const navigate = useNavigate();
  const { isInitialized, isAuthenticated } = useAuthContext();
  const { data, isLoading } = useUserContext();
  const isTemporary = data?.isTemporary;
  const quickBooking = localStorage.getItem('quickBooking');
  useEffect(() => {
    if (
      isInitialized &&
      isAuthenticated &&
      !isLoading &&
      data &&
      !isTemporary &&
      !quickBooking
    ) {
      navigate(PATH_DASHBOARD.root, { replace: true });
    }
  }, [isInitialized, isAuthenticated, isLoading, data, navigate, isTemporary]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
