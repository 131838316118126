// @mui
import {
  Alert,
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  useTheme,
} from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import { useResponsiveBreakpoints } from 'src/hooks/ueResponsiveBreakpoints';
// config
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
import { HEADER, NAV } from '../../../config-global';
//
import { useLocation } from 'react-router-dom';
import { useUserContext } from 'src/auth/useUserContext';
import Image from '../../../components/image';
import { PATH_DASHBOARD } from '../../../routes/paths';
import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { isUserRole } = useUserContext();

  const { themeLayout } = useSettingsContext();
  const { isMd } = useResponsiveBreakpoints();
  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const renderContent = (
    <>
      {(!isMd || isUserRole) && (
        <Stack
          sx={{ cursor: 'pointer' }}
          justifyContent="center"
          alignItems="center"
        >
          <Image
            disabledEffect
            visibleByDefault
            onClick={() =>
              window.location.replace(PATH_DASHBOARD.general.profiles.list)
            }
            alt="Vetsi Logo"
            src={'/assets/logo/logo_dashboard.svg'}
          />
        </Stack>
      )}

      {isMd && !isUserRole && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {/* <LanguagePopover /> */}

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <>
      <AppBar
        sx={{
          boxShadow:
            pathname === PATH_DASHBOARD.general.updateAccount || isUserRole
              ? theme.customShadows.z8
              : 'none',
          height: HEADER.H_MOBILE,
          zIndex: theme.zIndex.appBar + 1,
          ...bgBlur({
            color: theme.palette.background.default,
          }),
          transition: theme.transitions.create(['height'], {
            duration: theme.transitions.duration.shorter,
          }),
          ...(isDesktop && {
            width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
            height: HEADER.H_DASHBOARD_DESKTOP,
            ...(isOffset && {
              height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            }),
            ...(isNavHorizontal && {
              width: 1,
              bgcolor: 'background.default',
              height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            }),
            ...(isNavMini && {
              width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
            }),
          }),
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
          <Toolbar
            sx={{
              width: 1,
              minHeight: '56px !important',
              maxWidth: '1080px !important',

              '@media (max-width:1108px)': { px: 2 },
              '@media (min-width:1108px)': { px: 0 },
            }}
          >
            {renderContent}
          </Toolbar>
        </Box>
      </AppBar>
    </>
  );
}
