// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  professionalRegister: path(ROOTS_AUTH, '/professional-register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  confirmation: path(ROOTS_AUTH, '/confirmation'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  requestSent: path(ROOTS_AUTH, '/request-sent'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  professionalSchedule: path(ROOTS_DASHBOARD, '/professional-schedule'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  general: {
    updateAccount: path(ROOTS_DASHBOARD, '/update-account'),
    account: {
      root: path(ROOTS_DASHBOARD, '/account'),
      general: path(ROOTS_DASHBOARD, '/account/general'),
      business: path(ROOTS_DASHBOARD, '/account/business'),
      password: path(ROOTS_DASHBOARD, '/account/password'),
    },

    profiles: {
      root: path(ROOTS_DASHBOARD, '/profiles'),
      list: path(ROOTS_DASHBOARD, '/profiles/list'),
      upcomingVisits: path(ROOTS_DASHBOARD, '/profiles/upcoming-visits'),
      couponCodes: path(ROOTS_DASHBOARD, '/profiles/coupon-codes'),
    },
    profile: {
      root: (profileId: string) =>
        path(ROOTS_DASHBOARD, `/profile/${profileId}`),
      visit: (profileId: string) =>
        path(ROOTS_DASHBOARD, `/profile/${profileId}/visit`),
      notes: (profileId: string) =>
        path(ROOTS_DASHBOARD, `/profile/${profileId}/notes`),
      visitsHistory: (profileId: string) =>
        path(ROOTS_DASHBOARD, `/profile/${profileId}/visits-history`),
      files: (profileId: string) =>
        path(ROOTS_DASHBOARD, `/profile/${profileId}/files`),
    },
    visit: path(ROOTS_DASHBOARD, '/visit'),
    liveVisit: (visitId: string) =>
      path(ROOTS_DASHBOARD, `/live-visit/${visitId}`),
    waitingRoom: path(ROOTS_DASHBOARD, '/waiting-room'),
    visitsHistory: path(ROOTS_DASHBOARD, '/visits-history'),
    calendar: path(ROOTS_DASHBOARD, '/calendar'),
    booking: {
      firstSchedule: path(ROOTS_DASHBOARD, `/booking/first-schedule`),
      schedule: (profileId: string) =>
        path(ROOTS_DASHBOARD, `/booking/schedule/${profileId}`),
      reschedule: (profileId: string, visitId: string) =>
        path(ROOTS_DASHBOARD, `/booking/schedule/${profileId}/${visitId}`),
      completed: (visitId: string) =>
        path(ROOTS_DASHBOARD, `/booking/completed/${visitId}`),
    },
    petsy: {
      schedule: (profileId: string) =>
        path(ROOTS_DASHBOARD, `/booking/petsy-schedule/${profileId}`),
      reschedule: (profileId: string, visitId: string) =>
        path(
          ROOTS_DASHBOARD,
          `/booking/petsy-schedule/${profileId}/${visitId}`,
        ),
      completed: (visitId: string, referer?: string | null) =>
        path(
          ROOTS_DASHBOARD,
          referer
            ? `/booking/petsy-completed/${visitId}?referer=${referer}`
            : `/booking/petsy-completed/${visitId}`,
        ),
    },
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
};

export const QUICK_BOOKING = '/booking/book'

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE =
  'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE =
  'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION =
  'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
