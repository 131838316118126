import React from 'react';
import { Skeleton, Stack } from '@mui/material';

interface DateSkeletonProps {
  count: number;
}

const DateSkeleton: React.FC<DateSkeletonProps> = ({ count }) => {
  return (
    <Stack flexDirection="column" gap={1} flex={1} height={210}>
      <Stack gap={1}>
        {Array.from({ length: count }).map((_, j) => (
          <Skeleton
            key={j}
            variant="rounded"
            sx={{
              width: '80px',
              height: '32px',
              '@media (max-width:475px)': {
                width: '45px',
              },
            }}
          />
        ))}
      </Stack>

      <Stack gap={1}>
        {Array.from({ length: count }).map((_, j) => (
          <Skeleton
            key={j}
            variant="rounded"
            sx={{
              width: '80px',
              height: '32px',
              '@media (max-width:475px)': {
                width: '45px',
              },
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default DateSkeleton;
