// @mui
import { Stack, Typography } from '@mui/material';

import IconButton from '@mui/material/IconButton';
// routes
import React, { useState } from 'react';
import MenuPopover from 'src/components/menu-popover';

// ----------------------------------------------------------------------

type IconPopoverProps = {
  icon: React.ReactNode;
  title: string;
  body: string;
};

export default function IconPopover({ body, icon, title }: IconPopoverProps) {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <IconButton
        aria-label="info"
        id="button"
        aria-haspopup="true"
        onClick={handleOpenPopover}
        edge="end"
      >
        {icon}
      </IconButton>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="bottom-right"
        disabledArrow
      >
        <Stack sx={{ p: 1, maxWidth: 350 }}>
          <Typography variant="body1">{title}</Typography>
          <Typography variant="body2">{body}</Typography>
        </Stack>
      </MenuPopover>
    </>
  );
}
