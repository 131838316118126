import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { LoadingButton } from '@mui/lab';
import { useMutation, useQuery } from '@tanstack/react-query';
import { addDays, format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { profileApi } from 'src/api/profile';
import { promoCodeApi } from 'src/api/promoCode';
import { visitApi } from 'src/api/visit';
import { visitCategoriesApi } from 'src/api/visitCategories';
import VetsiIcon from 'src/assets/icons/VetsiIcon';
import { useUserContext } from 'src/auth/useUserContext';
import IconPopover from 'src/components/icon-popover/IconPopover';
import Iconify from 'src/components/iconify';
import { useUploadDocuments } from 'src/hooks/useUploadDocuments';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { fFloat } from 'src/utils/formatNumber';
import {
  GetUserDtoCategoriesEnum,
  RescheduleVisitDto,
  ScheduleVisitDto,
  ValidatePromoCodeDto,
} from 'woofwoof-api';
import FormProvider, {
  RHFCheckbox,
  RHFSelect,
  RHFTextField,
  RHFUploadBox,
} from '../../../../components/hook-form';
import { getFileIcon } from '../../profile/components/ProfileFilesTable';
import VisitCalendar from './VisitCalendar';
import { FileRow } from './VisitDetailsSkeleton';

export const VISIT_TYPE_MATRIX = {
  [GetUserDtoCategoriesEnum.Veterinarian]: 'Weterynarz',
  [GetUserDtoCategoriesEnum.Behaviorist]: 'Behawiorysta',
  [GetUserDtoCategoriesEnum.Dietitian]: 'Dietetyk',
  [GetUserDtoCategoriesEnum.Physiotherapist]: 'Fizjoterapeuta',
};

export type BookingFormValuesProps = {
  type: GetUserDtoCategoriesEnum;
  date: string;
  description: string;
  terms: boolean;
  files?: { filename: string; url: string }[] | null;
  promoCode?: string;
};

export default function BookingForm() {
  const navigate = useNavigate();
  const { isUserRole } = useUserContext();
  const [uploading, setUploading] = useState(false);
  const { upload, downloadURLs, error, remove } = useUploadDocuments();
  const { profileId, visitId } = useParams<{
    profileId: string;
    visitId: string;
  }>();
  const { data: profileData, isLoading: isFetchProfileLoading } = useQuery(
    ['bookingProfileData'],
    () =>
      profileApi.profileControllerGetUserProfile({
        profileId: profileId as string,
      }),
    {
      cacheTime: 0,
      retry: 1,
      enabled: Boolean(isUserRole),
    },
  );
  const { data: visitData, isLoading: isVisitLoading } = useQuery(
    ['rescheduleVisitData'],
    () => visitApi.visitControllerGetVisitById({ visitId: visitId as string }),
    {
      retry: 1,
      cacheTime: 0,
      enabled: Boolean(isUserRole) && Boolean(visitId),
    },
  );
  const { data: categoriesData, isLoading: isFetchCategoriesLoading } =
    useQuery(
      ['bookingCategoriesData'],
      () => visitCategoriesApi.visitCategoriesControllerGetAllVisitCategories(),
      {
        retry: 1,
        enabled: Boolean(isUserRole),
      },
    );
  const { mutateAsync: schedule, isLoading: isScheduleLoading } = useMutation({
    mutationFn: (scheduleVisitDto: ScheduleVisitDto) =>
      visitApi.visitControllerScheduleVisit({
        scheduleVisitDto,
      }),
  });
  const { mutateAsync: reschedule, isLoading: isRescheduleLoading } =
    useMutation({
      mutationFn: (rescheduleVisitDto: RescheduleVisitDto) =>
        visitApi.visitControllerRescheduleVisit({ rescheduleVisitDto }),
    });
  const {
    mutateAsync: applyPromoCode,
    isLoading: isPromoCodeLoading,
    data: promoCodeData,
    reset: removePromoCodeData,
  } = useMutation({
    mutationFn: (validatePromoCodeDto: ValidatePromoCodeDto) =>
      promoCodeApi.promoCodeControllerVerifyUser({ validatePromoCodeDto }),
  });
  const isPromoCodeApplied =
    Boolean(visitData?.data?.promoCode?.discountPercentage) ||
    Boolean(promoCodeData?.data?.discountPercentage) ||
    Boolean(promoCodeData?.data?.discountedPrice);
  const promoCodeDiscountPercentage =
    visitData?.data?.promoCode?.discountPercentage ||
    promoCodeData?.data?.discountPercentage;
  const promoDiscountedPrice = promoCodeData?.data?.discountedPrice;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  //TODO: Fix that ugly hack. Me not like it
  const visitFiles = visitId
    ? visitData?.data?.documents.map((document) => ({
        name: (document as any).filename,
        url: (document as any).url,
        progress: 100,
      }))
    : downloadURLs;

  const BookingSchema: Yup.Schema<BookingFormValuesProps> = Yup.object().shape({
    type: Yup.mixed<GetUserDtoCategoriesEnum>()
      .oneOf(Object.values(GetUserDtoCategoriesEnum))
      .required('Typ e-spotkania jest wymagany'),
    date: Yup.string().required('Data e-spotkania jest wymagana'),
    description: Yup.string().required('Opis e-spotkania jest wymagany'),
    files: Yup.array()
      .of(
        Yup.object().shape({
          filename: Yup.string().required(),
          url: Yup.string().required(),
        }),
      )
      .notRequired(),
    terms: Yup.boolean()
      .isTrue('Akceptacja regulaminu oraz polityki prywatności jest wymagana')
      .required(),
    promoCode: Yup.string(),
  });
  const VISIT_OPTIONS =
    categoriesData?.data.map(({ name }) => ({
      value: name,
      label: VISIT_TYPE_MATRIX[name],
    })) || [];

  const defaultValues: BookingFormValuesProps = {
    type: visitData?.data?.category || VISIT_OPTIONS[0]?.value || '',
    date: '',
    description: visitData?.data?.description || '',
    terms: visitData?.data ? true : false,
    files: null,
    promoCode: '',
  };

  const methods = useForm<BookingFormValuesProps>({
    resolver: yupResolver(BookingSchema),
    defaultValues,
    values: defaultValues,
  });

  const { setValue, handleSubmit, watch } = methods;

  const values = watch();
  const isPaymentBlocked =
    !values.date || !values.type || !values.description || !values.terms;
  const visitPrice = categoriesData?.data.find(
    ({ name }) => name === values.type,
  )?.price;

  const { data, isLoading, refetch, isFetching } = useQuery(
    ['bookingAvailableSlots'],
    () =>
      visitApi.visitControllerGetAvailableVisitSlots({
        startDate: new Date().toISOString(),
        endDate: addDays(new Date(), 12).toISOString(),
        category: values.type,
      }),
    {
      cacheTime: 0,
      enabled: false,
      retry: 1,
      select: (data) => {
        return data.data.slots.map((slot) => ({
          date: new Date(slot),
          available: true,
        }));
      },
    },
  );

  const handleOnSchedule = async (values: BookingFormValuesProps) => {
    if (profileId) {
      try {
        // TODO: Fix type
        const scheduleDTO = await schedule({
          startTime: new Date(values.date).toISOString(),
          description: values.description || '',
          category: values.type,
          profileId,
          profileName: '',
          documents: values.files ? [...values.files] : undefined,
          promoCode: isPromoCodeApplied ? values.promoCode : undefined,
        });

        // Visit not free
        if (scheduleDTO?.data?.amount !== 0) {
          window.location.replace(scheduleDTO?.data?.redirectUrl);
        } else {
          navigate(
            PATH_DASHBOARD.general.booking.completed(scheduleDTO.data.id),
          );
        }
      } catch (error) {
        enqueueSnackbar('Coś poszło nie tak', {
          variant: 'error',
        });
      }
    }
  };

  const handleOnReschedule = async (values: BookingFormValuesProps) => {
    if (profileId && visitId) {
      try {
        await reschedule({
          newStartTime: new Date(values.date).toISOString(),
          visitId,
        });
        navigate(PATH_DASHBOARD.general.profiles.list);
        enqueueSnackbar('Termin e-spotkania został zmieniony', {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar('Coś poszło nie tak', {
          variant: 'error',
        });
      }
    }
  };

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const fileNamesToUpload = acceptedFiles.map((file) => file.name);
      const isFileAlreadyUploaded = fileNamesToUpload.some((item) =>
        downloadURLs.map((item) => item.name.split('_')[1]).includes(item),
      );

      // TODO: check if file name and extension of the file is the same, not just the name. We could display name of already uploaded file
      if (isFileAlreadyUploaded) {
        enqueueSnackbar('Załącznik został już dodany', {
          variant: 'error',
        });
        return;
      }
      setUploading(true);
      upload(acceptedFiles);
    },
    [upload, downloadURLs, enqueueSnackbar],
  );

  const handleRemove = (fileName: string) => {
    remove(fileName);
  };

  const handleApplyPromoCode = useCallback(async () => {
    if (values.promoCode && values.type) {
      try {
        await applyPromoCode({ code: values.promoCode, category: values.type });
      } catch (error) {
        enqueueSnackbar(
          'Kod rabatowy jest niepoprawny lub dotyczy innego typu e-spotkania',
          {
            variant: 'error',
          },
        );
      }
    }
  }, [values.promoCode, values.type, applyPromoCode, enqueueSnackbar]);

  const handleRemovePromoCode = () => {
    setValue('promoCode', '');
    removePromoCodeData();
  };

  useEffect(() => {
    if (!visitId && values.type) {
      if (values.type !== GetUserDtoCategoriesEnum.Veterinarian) {
        refetch();
      }

      setValue('date', '');
      setValue('promoCode', '');
      removePromoCodeData();
    }

    if (visitId && values.type === visitData?.data.category) {
      refetch();
      setValue('date', '');
      setValue('promoCode', '');
      removePromoCodeData();
    }
  }, [
    values.type,
    visitId,
    visitData?.data.category,
    refetch,
    setValue,
    removePromoCodeData,
  ]);

  useEffect(() => {
    if (downloadURLs.length > 0) {
      setValue(
        'files',
        downloadURLs.map((file) => ({ url: file.url, filename: file.name })),
      );
      setUploading(false);
    }
  }, [downloadURLs, setValue]);

  useEffect(() => {
    if (error) {
      setUploading(false);
    }
  }, [error]);

  useEffect(() => {
    return () => {
      removePromoCodeData();
    };
  }, [removePromoCodeData]);

  const resolvedPrice = promoDiscountedPrice
    ? promoDiscountedPrice / 100
    : undefined;
  const resolvedPercentageDiscount =
    visitPrice && promoCodeDiscountPercentage
      ? fFloat(visitPrice - (visitPrice * promoCodeDiscountPercentage) / 100)
      : undefined;

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(visitId ? handleOnReschedule : handleOnSchedule)}
      style={{ width: '100%' }}
    >
      <Grid
        container
        spacing={4}
        sx={{ px: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 } }}
      >
        <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
          <Paper
            sx={{ p: 3, borderRadius: 2, boxShadow: theme.customShadows.z8 }}
          >
            <Stack spacing={2}>
              <Typography variant="h6">Wybierz typ e-spotkania</Typography>
              {isVisitLoading && visitId ? (
                <Skeleton variant="rounded" width="100%" height={56} />
              ) : (
                <RHFSelect
                  name="type"
                  label="Typ e-spotkania"
                  disabled={
                    isScheduleLoading || !!visitId || isFetchCategoriesLoading
                  }
                >
                  {VISIT_OPTIONS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              )}
              <Typography variant="h6">Wybierz termin</Typography>
              <VisitCalendar
                dates={
                  values.type === GetUserDtoCategoriesEnum.Veterinarian
                    ? []
                    : data || []
                }
                onSelect={(date) => setValue('date', date as any)}
                disabled={
                  isScheduleLoading ||
                  isRescheduleLoading ||
                  isFetchCategoriesLoading
                }
                isLoading={
                  values.type === GetUserDtoCategoriesEnum.Veterinarian
                    ? false
                    : isLoading || isFetching || isFetchCategoriesLoading
                }
                noDatesPlaceholder={
                  values.type === GetUserDtoCategoriesEnum.Veterinarian
                    ? 'Brak możliwości umówienia e-spotkania z weterynarzem'
                    : undefined
                }
              />
              <Typography variant="h6">Informacje dla specjalisty</Typography>
              {isVisitLoading && visitId ? (
                <Skeleton variant="rounded" width="100%" height={125} />
              ) : (
                <RHFTextField
                  disabled={
                    isScheduleLoading || !!visitId || isFetchCategoriesLoading
                  }
                  name="description"
                  label="Cel e-spotkania"
                  placeholder="Opisz dolegliwości, choroby lub inne ważne kwestie o których powinien wiedzieć specjalista"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconPopover
                          title="Potrzebujesz recepty?"
                          body="Zgodnie z obowiązującymi przepisami w Polsce, recepta weterynaryjna może zostać wystawiona wyłącznie podczas wizyty w gabinecie weterynaryjnym. Niestety, podczas e-spotkania nie ma możliwości wystawienia recepty. Warto jednak pamiętać, że nie zawsze jest ona konieczna – podczas e-spotkania pomożemy znaleźć najlepsze rozwiązanie dla Twojego pupila!"
                          icon={<Iconify icon={'eva:info-outline'} />}
                        />
                      </InputAdornment>
                    ),
                  }}
                  multiline
                  rows={4}
                  required
                />
              )}
              {visitId ? (
                visitFiles && visitFiles.length > 0 ? (
                  <>
                    <Typography variant="h6">Załączniki</Typography>
                  </>
                ) : null
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="h6">Załączniki</Typography>
                </Box>
              )}
              {isVisitLoading && visitId ? (
                <>
                  {FileRow}
                  {FileRow}
                  {FileRow}
                </>
              ) : (
                visitFiles &&
                visitFiles?.length > 0 && (
                  <Stack sx={{ gap: 2 }}>
                    {visitFiles?.map(({ name, progress }, index) => (
                      <Stack
                        key={index}
                        sx={{
                          gap: 1.5,
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: 2,
                            flex: 1,
                          }}
                        >
                          <Box
                            sx={{
                              padding: 1,
                              borderRadius: 1,
                              backgroundColor: theme.palette.background.neutral,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {getFileIcon(name)}
                          </Box>
                          <Box sx={{ display: 'flex', flex: 1 }}>
                            {progress === 100 ? (
                              <Typography>{name.split('_')[1]}</Typography>
                            ) : (
                              <LinearProgress
                                variant="determinate"
                                value={progress}
                                sx={{ width: 1, height: 5 }}
                              />
                            )}
                          </Box>
                        </Box>
                        {!visitId && (
                          <IconButton
                            onClick={() => handleRemove(name)}
                            color="error"
                            disabled={uploading}
                          >
                            <span>
                              <Iconify icon="eva:trash-2-outline" />
                            </span>
                          </IconButton>
                        )}
                      </Stack>
                    ))}
                  </Stack>
                )
              )}
              {!visitId && (
                <RHFUploadBox
                  sx={{
                    flex: 1,
                    height: 'inherit',
                    borderRadius: 1.5,
                    width: 1,
                    minHeight: 150,
                  }}
                  name="background"
                  maxSize={52428800}
                  onDrop={(files) => handleDrop(files)}
                  placeholder={
                    <Stack sx={{ gap: 0.5, p: 3, flex: 1 }} alignItems="center">
                      {!uploading ? (
                        <>
                          <Iconify icon="eva:cloud-upload-fill" width={40} />
                          <Typography variant="body2">Załącz pliki</Typography>
                          <Typography variant="caption">
                            Maksymalny rozmiar plików wideo: 50 mb
                          </Typography>
                        </>
                      ) : null}
                    </Stack>
                  }
                  disabled={
                    isScheduleLoading ||
                    uploading ||
                    !!visitId ||
                    isFetchCategoriesLoading
                  }
                />
              )}
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
          <Paper
            sx={{ p: 3, borderRadius: 2, boxShadow: theme.customShadows.z8 }}
          >
            <Stack spacing={2}>
              <Typography variant="h6">Podsumowanie</Typography>
              {profileData?.data && !isFetchProfileLoading ? (
                <Box
                  sx={{
                    gap: 2,
                    display: 'flex',
                    borderRadius: 2,
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    src={
                      profileData?.data.avatar || '/assets/avatars/avatar4.png'
                    }
                    sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
                  />
                  <Typography variant="body1">
                    {profileData?.data?.name}
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    gap: 2,
                    display: 'flex',
                    borderRadius: 2,
                    alignItems: 'center',
                  }}
                >
                  <Skeleton variant="circular" width={32} height={32} />
                  <Skeleton variant="rounded" width={80} height={20} />
                </Box>
              )}
              {(isVisitLoading && visitId) || isFetchCategoriesLoading ? (
                <Skeleton variant="rounded" width="100%" height={24} />
              ) : (
                <Stack
                  sx={{
                    gap: 2,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <VetsiIcon />
                  <Typography>
                    Typ e-spotkania: {VISIT_TYPE_MATRIX[values.type] || ''}
                  </Typography>
                </Stack>
              )}

              {isVisitLoading && visitId ? (
                <Skeleton variant="rounded" width="100%" height={24} />
              ) : (
                visitData?.data?.startTime && (
                  <Stack
                    sx={{
                      gap: 2,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <VetsiIcon />
                    <Typography>
                      Aktualny termin:{' '}
                      {format(
                        new Date(visitData.data.startTime),
                        'd MMMM y, H:mm',
                        {
                          locale: pl,
                        },
                      )}
                    </Typography>
                  </Stack>
                )
              )}
              {values.date && (
                <Stack
                  sx={{
                    gap: 2,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <VetsiIcon />
                  <Typography>
                    {visitId ? `Nowy termin` : `Termin`}:{' '}
                    {format(new Date(values.date), 'd MMMM y, H:mm', {
                      locale: pl,
                    })}
                  </Typography>
                </Stack>
              )}
              {/* <Divider />
              <Typography variant="h6">Specjalista</Typography>
              <Box
                sx={{
                  gap: 2,
                  display: 'flex',
                  borderRadius: 2,
                  alignItems: 'center',
                }}
              >
                <Avatar
                  src="/assets/avatars/doctor.jpg"
                  sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
                />
                <Typography variant="body1">Renata Rajczyk</Typography>
              </Box> */}
              {isVisitLoading && visitId ? (
                <Skeleton variant="rounded" width="100%" height={56} />
              ) : (
                !visitId && (
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 2,
                    }}
                  >
                    <RHFTextField
                      name="promoCode"
                      label="Kod rabatowy"
                      size="medium"
                      disabled={
                        isFetchCategoriesLoading ||
                        isPromoCodeLoading ||
                        isPromoCodeApplied
                      }
                      InputProps={{
                        sx: { pr: '4px' },
                      }}
                    />
                    <LoadingButton
                      onClick={
                        !isPromoCodeApplied
                          ? handleApplyPromoCode
                          : handleRemovePromoCode
                      }
                      variant="contained"
                      color={isPromoCodeApplied ? 'error' : 'primary'}
                      size="large"
                      loading={isPromoCodeLoading}
                      disabled={isPromoCodeLoading}
                    >
                      <span>{isPromoCodeApplied ? 'Usuń' : 'Dodaj'}</span>
                    </LoadingButton>
                  </Stack>
                )
              )}
              {(isVisitLoading && visitId) || isFetchCategoriesLoading ? (
                <Skeleton variant="rounded" width="100%" height={30} />
              ) : (
                <Stack
                  sx={{ flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  {visitPrice && (
                    <>
                      <Typography variant="h5">Cena e-spotkania</Typography>
                      <Typography variant="h5">
                        {isPromoCodeApplied && (
                          <Typography
                            color="text.secondary"
                            component="span"
                            sx={{ textDecoration: 'line-through' }}
                          >
                            {visitPrice} zł
                          </Typography>
                        )}{' '}
                        <Typography
                          component="span"
                          color={
                            isPromoCodeApplied ? 'success.main' : undefined
                          }
                        >
                          {typeof resolvedPrice !== 'undefined'
                            ? resolvedPrice
                            : typeof resolvedPercentageDiscount !== 'undefined'
                            ? resolvedPercentageDiscount
                            : visitPrice}{' '}
                          zł
                        </Typography>
                      </Typography>
                    </>
                  )}
                </Stack>
              )}

              <Divider />
              <Stack spacing={1}>
                {!visitId && (
                  <RHFCheckbox
                    name="terms"
                    label=""
                    disabled={
                      isLoading || isFetching || isFetchCategoriesLoading
                    }
                    customLabel={
                      <Typography variant="body2">
                        Akceptuję{' '}
                        <Link
                          underline="always"
                          color="text.primary"
                          href="https://vetsi.pl/regulamin"
                          target="_blank"
                        >
                          regulamin
                        </Link>{' '}
                        i{' '}
                        <Link
                          underline="always"
                          color="text.primary"
                          href="https://vetsi.pl/polityka-prywatnosci"
                          target="_blank"
                        >
                          politykę prywatności
                        </Link>{' '}
                        Vetsi sp. z o.o. oraz zapoznałem się z prawem do
                        odstąpienia od umowy.*
                      </Typography>
                    }
                    sx={{ mr: 1 }}
                  />
                )}
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  loading={isScheduleLoading || isRescheduleLoading}
                  loadingIndicator={
                    <span>
                      <CircularProgress color="inherit" size={16} />
                    </span>
                  }
                  disabled={
                    isPaymentBlocked || isScheduleLoading || isRescheduleLoading
                  }
                >
                  <span>{visitId ? 'Zmień termin' : 'Płatność'}</span>
                </LoadingButton>
                {!visitId && (
                  <Typography
                    variant="caption"
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    W następnym kroku przeniesiemy Cię do portalu płatniczego.
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
