import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// @mui
import { Box, Button, Stack } from '@mui/material';
// components
import { RHFAutocomplete, RHFTextField } from '../hook-form';
import Iconify from '../iconify';
// api
import { petBreedApi } from 'src/api/petBreed';
// types
import { PetBreedDto, PetBreedDtoCategoryEnum } from 'woofwoof-api';

// ----------------------------------------------------------------------

type PetBreedSelectorProps = {
  name: string;
  disabled?: boolean;
  defaultValue?: string;
};

export default function PetBreedSelector({
  name,
  disabled = false,
  defaultValue,
}: PetBreedSelectorProps) {
  const [petType, setPetType] = useState<
    PetBreedDtoCategoryEnum | 'other' | null
  >(null);
  const [inputValue, setInputValue] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(inputValue);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  const { data: breeds = [], isLoading } = useQuery(
    ['petBreeds', petType, debouncedSearchQuery],
    async () => {
      if (!petType || petType === 'other') return { data: [] };
      return petType === PetBreedDtoCategoryEnum.Dog
        ? petBreedApi.petBreedControllerGetDogs({
            search: debouncedSearchQuery,
          })
        : petBreedApi.petBreedControllerGetCats({
            search: debouncedSearchQuery,
          });
    },
    {
      select: (response) => response.data || [],
      enabled: !!petType && petType !== 'other', // Only run query if petType is dog or cat
    },
  );

  return (
    <Box>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mb: petType === null ? 0 : 2 }}
        justifyContent="space-between"
      >
        <Button
          variant={
            petType === PetBreedDtoCategoryEnum.Dog ? 'contained' : 'outlined'
          }
          onClick={() => setPetType(PetBreedDtoCategoryEnum.Dog)}
          startIcon={<Iconify icon="mdi:dog" />}
          disabled={disabled}
          color={
            petType === PetBreedDtoCategoryEnum.Dog ? 'primary' : 'inherit'
          }
          fullWidth
        >
          Pies
        </Button>
        <Button
          variant={
            petType === PetBreedDtoCategoryEnum.Cat ? 'contained' : 'outlined'
          }
          onClick={() => setPetType(PetBreedDtoCategoryEnum.Cat)}
          startIcon={<Iconify icon="mdi:cat" />}
          disabled={disabled}
          color={
            petType === PetBreedDtoCategoryEnum.Cat ? 'primary' : 'inherit'
          }
          fullWidth
        >
          Kot
        </Button>
        <Button
          variant={petType === 'other' ? 'contained' : 'outlined'}
          onClick={() => setPetType('other')}
          startIcon={<Iconify icon="mdi:paw" />}
          disabled={disabled}
          color={petType === 'other' ? 'primary' : 'inherit'}
          fullWidth
        >
          Inny
        </Button>
      </Stack>

      {petType === 'other' ? (
        <RHFTextField
          name={name}
          label="Rodzaj zwierzęcia"
          disabled={disabled}
          defaultValue={defaultValue}
          placeholder="Np. Królik, Chomik, Papuga..."
        />
      ) : (
        petType && (
          <RHFAutocomplete<PetBreedDto | string, false, false, false>
            name={name}
            label="Rasa"
            disabled={disabled}
            options={breeds as any}
            loading={isLoading}
            defaultValue={defaultValue}
            getOptionLabel={(option: PetBreedDto | string) => {
              if (typeof option === 'string') return option;
              return option.namePL;
            }}
            isOptionEqualToValue={(option, value) => {
              if (typeof option === 'string' || typeof value === 'string') {
                return typeof option !== 'string'
                  ? option.namePL === value
                  : option === value;
              }
              return option.namePL === value.namePL;
            }}
            onChange={(event, newValue) => {
              // For string values, return as is
              if (typeof newValue === 'string') return newValue;
              // For PetBreedDto objects, return the namePL property as a string
              // This ensures we always return a string value to the form
              return newValue?.namePL || '';
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderOption={(props, option) => (
              <li
                {...props}
                key={typeof option === 'string' ? option : option.nameEN}
              >
                {typeof option === 'string' ? option : option.namePL}
              </li>
            )}
          />
        )
      )}
    </Box>
  );
}
