import { ElementType, Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(
  lazy(() => import('../pages/auth/LoginPage')),
);
export const RegisterPage = Loadable(
  lazy(() => import('../pages/auth/RegisterPage')),
);
export const ProfessionalRegisterPage = Loadable(
  lazy(() => import('../pages/auth/ProfessionalRegisterPage')),
);
export const ConfirmationPage = Loadable(
  lazy(() => import('../pages/auth/ConfirmationPage')),
);
export const VerificationPage = Loadable(
  lazy(() => import('../pages/auth/VerificationPage')),
);
export const NewPasswordPage = Loadable(
  lazy(() => import('../pages/auth/NewPasswordPage')),
);
export const ResetPasswordPage = Loadable(
  lazy(() => import('../pages/auth/ResetPasswordPage')),
);
export const ResetPasswordRequestSentPage = Loadable(
  lazy(() => import('../pages/auth/ResetPasswordRequestSentPage')),
);

// BOOKING
export const QuickBookingPage = Loadable(
  lazy(() => import('../pages/booking/QuickBookingPage')),
);

// DASHBOARD: GENERAL
export const VisitPage = Loadable(
  lazy(() => import('../pages/dashboard/VisitPage')),
);
export const WaitingRoomPage = Loadable(
  lazy(() => import('../pages/dashboard/WaitingRoomPage')),
);
export const ProfilesPage = Loadable(
  lazy(() => import('../pages/dashboard/ProfilesPage')),
);
export const ProfilePage = Loadable(
  lazy(() => import('../pages/dashboard/ProfilePage')),
);
export const ProfileTabsVisitsHistoryPage = Loadable(
  lazy(() => import('../pages/dashboard/ProfileTabsVisitsHistoryPage')),
);
export const ProfileTabsFilesPage = Loadable(
  lazy(() => import('../pages/dashboard/ProfileTabsFilesPage')),
);
export const ProfileTabsVisitPage = Loadable(
  lazy(() => import('../pages/dashboard/ProfileTabsVisitPage')),
);
export const ProfileTabsNotesPage = Loadable(
  lazy(() => import('../pages/dashboard/ProfileTabsNotesPage')),
);
export const BookingPage = Loadable(
  lazy(() => import('../pages/dashboard/BookingPage')),
);
export const FirstBookingPage = Loadable(
  lazy(() => import('../pages/dashboard/FirstBookingPage')),
);
export const LiveVisitPage = Loadable(
  lazy(() => import('../pages/dashboard/LiveVisitPage')),
);
export const BookingCompletedPage = Loadable(
  lazy(() => import('../pages/dashboard/BookingCompletedPage')),
);
export const VisitsHistoryPage = Loadable(
  lazy(() => import('../pages/dashboard/VisitsHistoryPage')),
);
export const CalendarPage = Loadable(
  lazy(() => import('../pages/dashboard/CalendarPage')),
);
export const ProfessionalSchedulePage = Loadable(
  lazy(() => import('../pages/dashboard/ProfessionalSchedulePage')),
);
export const UpdateAccountPage = Loadable(
  lazy(() => import('../pages/dashboard/UpdateAccountPage')),
);
export const CouponCodesPage = Loadable(
  lazy(() => import('../pages/dashboard/CouponCodesPage')),
);

// DASHBOARD: USER;
export const AccountPage = Loadable(
  lazy(() => import('../pages/dashboard/AccountPage')),
);
export const AccountTabsGeneralPage = Loadable(
  lazy(() => import('../pages/dashboard/AccountTabsGeneralPage')),
);
export const AccountTabsBusinessPage = Loadable(
  lazy(() => import('../pages/dashboard/AccountTabsBusinessPage')),
);
export const AccountTabsPasswordPage = Loadable(
  lazy(() => import('../pages/dashboard/AccountTabsPasswordPage')),
);

// PETSY
export const PetsyBookingPage = Loadable(
  lazy(() => import('../pages/dashboard/petsy/PetsyBookingPage')),
);
export const PetsyBookingCompletedPage = Loadable(
  lazy(() => import('../pages/dashboard/petsy/PetsyBookingCompletedPage')),
);

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(
  lazy(() => import('../pages/dashboard/PermissionDeniedPage')),
);

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const MaintenancePage = Loadable(
  lazy(() => import('../pages/MaintenancePage')),
);
