// @mui
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Stack,
  alpha,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect } from 'react';
import useImageCompression from 'src/hooks/useImageCompression';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// components
import { LoadingButton } from '@mui/lab';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { useMutation } from '@tanstack/react-query';
import { parseISO } from 'date-fns';
import { profileApi } from 'src/api/profile';
import PetBreedSelector from 'src/components/pet-breed-selector/PetBreedSelector';
import {
  CreateProfileDto,
  GetProfileDto,
  UpdateProfileDto,
} from 'woofwoof-api';
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from '../../../../components/hook-form';
import { useSnackbar } from '../../../../components/snackbar';

// ----------------------------------------------------------------------

export type ProfileModalFormValuesProps = {
  avatar?: string;
  background?: string;
  name: string;
  breed: string;
  dob: string;
  weight: number;
  chipNumber?: string;
  gender: string;
  description?: string;
};

type ProfileModalProps = {
  isOpen: boolean;
  onClose: () => void;
  profile?: GetProfileDto;
  onSubmited?: (isEdit: boolean) => void;
};

export const GENDER_OPTIONS = [
  { value: 'Samiec', label: 'Samiec' },
  { value: 'Samica', label: 'Samica' },
  { value: 'Inna', label: 'Inna' },
];

export default function ProfileModal({
  isOpen,
  onClose,
  profile,
  onSubmited,
}: ProfileModalProps) {
  const theme = useTheme();
  const { compressImage, imageLoading } = useImageCompression();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: createProfile, isLoading: isCreateLoading } =
    useMutation({
      mutationFn: (createProfileDto: CreateProfileDto) =>
        profileApi.profileControllerCreateProfile({ createProfileDto }),
    });
  const { mutateAsync: updateProfile, isLoading: isUpdateLoading } =
    useMutation({
      mutationFn: ({
        updateProfileDto,
        profileId,
      }: {
        updateProfileDto: UpdateProfileDto;
        profileId: GetProfileDto['id'];
      }) =>
        profileApi.profileControllerUpdateUser({ updateProfileDto, profileId }),
    });

  const UpdateProfileSchema: Yup.Schema<ProfileModalFormValuesProps> =
    Yup.object().shape({
      avatar: Yup.string(),
      background: Yup.string(),
      name: Yup.string()
        .required('Imię jest wymagane')
        .max(20, 'Imię nie może przekraczać 20 znaków'),
      breed: Yup.string().required('Rasa jest wymagana'),
      gender: Yup.string().required('Płeć jest wymagana'),
      dob: Yup.string().required('Wiek jest wymagany'),
      weight: Yup.number()
        .required('Waga jest wymagana')
        .min(0.1, 'Waga musi być większa od 0')
        .typeError('Podaj poprawną liczbę'),
      chipNumber: Yup.string().test(
        'empty-check',
        'Numer mikroczipa musi mieć 15 znaków',
        (val) => val?.length === 15 || !val,
      ),

      description: Yup.string().max(
        200,
        'Opis nie może przekraczać 200 znaków',
      ),
    });

  const defaultValues: ProfileModalFormValuesProps = {
    avatar: profile?.avatar || '',
    background: profile?.background || '',
    name: profile?.name || '',
    breed: profile?.breed || '',
    gender: profile?.gender || '',
    dob: profile?.dob || '',
    weight: profile?.weight || 0,
    chipNumber: profile?.chipNumber || '',
    description: profile?.description || '',
  };

  const methods = useForm<ProfileModalFormValuesProps>({
    resolver: yupResolver(UpdateProfileSchema),
    defaultValues,
    values: defaultValues,
  });

  const { setValue, handleSubmit, watch, reset } = methods;

  const dob = watch('dob');
  const dobValue = dob ? parseISO(dob) : null;

  const handleOnSubmit = async (data: ProfileModalFormValuesProps) => {
    try {
      profile
        ? await updateProfile({
            updateProfileDto: {
              ...data,
              weight: Number(data.weight),
              chipNumber: data.chipNumber ? data.chipNumber : undefined,
            } as UpdateProfileDto,
            profileId: profile?.id,
          })
        : await createProfile({
            ...data,
            weight: Number(data.weight),
            chipNumber: data.chipNumber ? data.chipNumber : undefined,
          } as CreateProfileDto);
      onSubmited && onSubmited(Boolean(profile));
      onClose();
      enqueueSnackbar(
        profile
          ? 'Dane Twojego zwierzaka zostały zaktualizowane'
          : 'Twój zwierzak został dodany',
        { variant: 'success' },
      );
    } catch (error) {
      enqueueSnackbar(error.message || error, {
        variant: 'error',
      });
    }
  };

  const handleDrop = useCallback(
    async (acceptedFiles: File[], name: keyof ProfileModalFormValuesProps) => {
      try {
        const file = await compressImage(acceptedFiles);
        if (file) {
          setValue(name, file as string, { shouldValidate: true });
        }
      } catch (error) {
        enqueueSnackbar('Wystąpił błąd', {
          variant: 'error',
        });
      }
    },
    [setValue, enqueueSnackbar, compressImage],
  );

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: {
            sm: 'calc(100% - 32px)',
            md: '540px',
            lg: '640px',
            xl: '640px',
          },
        },
      }}
    >
      <DialogTitle>
        {profile ? 'Edycja' : 'Dodaj swojego zwierzaka'}
      </DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(handleOnSubmit)}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {profile
              ? 'Aktualizuj dane profilu swojego zwierzaka.'
              : 'Stwórz profil, który będzie widoczny dla Ciebie oraz specjalistów.'}
          </DialogContentText>
          <Stack sx={{ pt: 2, pb: 2, gap: 3 }}>
            <Stack sx={{ flexDirection: 'row' }}>
              {imageLoading ? (
                <Box
                  sx={{
                    width: 144,
                    height: 144,
                    display: 'flex',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    borderRadius: '50%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    border: `1px dashed ${alpha(
                      theme.palette.grey[500],
                      0.32,
                    )}`,
                  }}
                >
                  <Box
                    sx={{
                      zIndex: 7,
                      display: 'flex',
                      borderRadius: '50%',
                      position: 'absolute',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 128,
                      height: 128,
                      color: theme.palette.text.disabled,
                      backgroundColor: theme.palette.primary.lighter,
                      transition: theme.transitions.create('opacity', {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter,
                      }),
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </Box>
              ) : (
                <RHFUploadAvatar
                  name="avatar"
                  onDrop={(files) => handleDrop(files, 'avatar')}
                  disabled={isCreateLoading || isUpdateLoading}
                />
              )}
            </Stack>

            <PetBreedSelector
              name="breed"
              disabled={isCreateLoading || isUpdateLoading}
              defaultValue={profile?.breed}
            />

            <RHFTextField
              required
              name="name"
              label="Imię"
              disabled={isCreateLoading || isUpdateLoading}
            />

            <RHFSelect
              required
              name="gender"
              label="Płeć"
              disabled={isCreateLoading || isUpdateLoading}
            >
              {GENDER_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </RHFSelect>

            <MobileDatePicker
              DialogProps={{
                sx: (theme) => ({
                  '& .MuiDialogActions-root': { display: 'none' },
                  '& .MuiButtonBase-root.MuiPickersDay-root:focus.Mui-selected':
                    {
                      backgroundColor: theme.palette.primary.main,
                    },
                }),
              }}
              showToolbar={false}
              label="Data urodzenia"
              closeOnSelect
              inputFormat="dd/MM/yyyy"
              disabled={isCreateLoading || isUpdateLoading}
              value={dobValue}
              maxDate={new Date()}
              onChange={(newValue: any) => {
                if (newValue) {
                  setValue('dob', newValue?.toISOString() || '');
                }
              }}
              renderInput={(params) => (
                <RHFTextField
                  {...params}
                  name="dob"
                  disabled={isCreateLoading || isUpdateLoading}
                  required
                />
              )}
            />

            <RHFTextField
              required
              name="weight"
              label="Waga (kg)"
              disabled={isCreateLoading || isUpdateLoading}
            />

            <RHFTextField
              name="description"
              label="Opisz swojego zwierzaka"
              multiline
              rows={4}
              disabled={isCreateLoading || isUpdateLoading}
            />

            <RHFTextField
              name="chipNumber"
              label="Numer mikroczipa"
              disabled={isCreateLoading || isUpdateLoading}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            Zamknij
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isCreateLoading || isUpdateLoading}
          >
            <span>{profile ? 'Zapisz' : 'Dodaj'}</span>
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
