import { getApp } from 'firebase/app';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
  deleteObject,
} from 'firebase/storage';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';

type UploadStatus = {
  downloadURLs: { name: string; url: string; progress: number }[];
  upload: (files: File[]) => void;
  error: boolean;
  reset: () => void;
  remove: (fileName: string) => void;
  setInitialFiles: (files: { name: string; url: string; progress: number }[]) => void;
};

export const useUploadDocuments = (): UploadStatus => {
  const [error, setError] = useState(false);
  const [downloadURLs, setDownloadURLs] = useState<
    UploadStatus['downloadURLs']
  >([]);

  const { enqueueSnackbar } = useSnackbar();
  const app = getApp();

  const upload = useCallback(
    async (files: File[]) => {
      const storage = getStorage(app);

      files.forEach((file) => {
        const uniqueFileName = `documents/${new Date().getTime()}_${file.name}`;
        const storageRef = ref(storage, uniqueFileName);
        const uploadTask = uploadBytesResumable(storageRef, file);

        // Initialize progress for each file
        setDownloadURLs((prevURLs) => [
          ...prevURLs,
          { name: uniqueFileName, url: '', progress: 0 },
        ]);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // Update progress for the specific file
            setDownloadURLs((prevURLs) =>
              prevURLs.map((item) =>
                item.name === uniqueFileName ? { ...item, progress } : item,
              ),
            );
          },
          (error) => {
            setError(true);
            enqueueSnackbar('Nie mogliśmy dodać załączników', {
              variant: 'error',
            });
          },
          async () => {
            try {
              const url = await getDownloadURL(uploadTask.snapshot.ref);
              // Update URL and progress for the specific file
              setDownloadURLs((prevURLs) =>
                prevURLs.map((item) =>
                  item.name === uniqueFileName
                    ? { ...item, url, progress: 100 }
                    : item,
                ),
              );
            } catch (error) {
              setError(true);
              enqueueSnackbar('Nie mogliśmy dodać załączników', {
                variant: 'error',
              });
            }
          },
        );
      });
    },
    [enqueueSnackbar, app],
  );

  const remove = useCallback(
    (fileName: string) => {
      const storage = getStorage(app);
      const fileRef = ref(storage, fileName);

      deleteObject(fileRef)
        .then(() => {
          setDownloadURLs((prevURLs) =>
            prevURLs.filter((file) => file.name !== fileName),
          );
        })
        .catch((error) => {
          setError(true);
          enqueueSnackbar('Nie mogliśmy usunąć pliku', {
            variant: 'error',
          });
        });
    },
    [enqueueSnackbar, app],
  );

  const reset = () => setDownloadURLs([]);

  const setInitialFiles = useCallback(
    (files: { name: string; url: string; progress: number }[]) => {
      setDownloadURLs(files);
    },
    []
  );

  return {
    downloadURLs,
    upload,
    reset,
    error,
    remove,
    setInitialFiles,
  };
};