// @mui
import { Stack, Typography } from '@mui/material';

import IconButton from '@mui/material/IconButton';
// routes
import { useState } from 'react';
import Iconify from 'src/components/iconify';
import MenuPopover from 'src/components/menu-popover';

// ----------------------------------------------------------------------

type PhoneNumberInfoPopoverProps = {
  copy?: string;
};

export default function PhoneNumberInfoPopover({
  copy,
}: PhoneNumberInfoPopoverProps) {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <IconButton
        aria-label="info"
        id="button"
        aria-haspopup="true"
        onClick={handleOpenPopover}
        edge="end"
      >
        <Iconify icon={'eva:info-outline'} />
      </IconButton>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="bottom-right"
        disabledArrow
      >
        <Stack sx={{ p: 1, maxWidth: 350 }}>
          <Typography variant="body1">
            Do czego wykorzystujemy numer telefonu?
          </Typography>
          <Typography variant="body2">
            {copy ||
              `Podając nam swój numer telefonu, pomożesz nam na szybszy kontakt w
            celu przekazania informacji lub zmian w związku z umówionym
            e-spotkaniem. Numer telefonu nie jest wymagany do rejestracji.`}
          </Typography>
        </Stack>
      </MenuPopover>
    </>
  );
}
