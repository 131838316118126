import { PostHogProvider } from 'posthog-js/react';
import ReactDOM from 'react-dom/client';

import { PostHogConfig } from 'posthog-js';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const isProduction = process.env.NODE_ENV === 'production';

// POSTHOG
const options: Partial<PostHogConfig> = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST as string,
};

// HOTJAR
// if (HOTJAR_API.siteId && HOTJAR_API.version) {
//   Hotjar.init(Number(HOTJAR_API.siteId), Number(HOTJAR_API.version));
// }

// SENTRY
// if (SENTRY_API.dsn) {
//   Sentry.init({
//     dsn: SENTRY_API.dsn,
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       Sentry.replayIntegration(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//     environment: 'production',
//     enabled: !!SENTRY_API.dsn,
//   });
// }

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  isProduction ? (
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string}
      options={options}
    >
      <App />
    </PostHogProvider>
  ) : (
    <App />
  ),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
