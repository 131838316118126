import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
//
import { PATH_AUTH, PATH_DASHBOARD, QUICK_BOOKING } from 'src/routes/paths';
import { useAuthContext } from './useAuthContext';
import { useUserContext } from './useUserContext';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, loginWithCustomToken, logout } =
    useAuthContext();
  const { isLoading, isProfessionalRole, isUserRole, data } = useUserContext();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const redirect = searchParams.get('redirect');
  const token = searchParams.get('token');
  const orderId = searchParams.get('orderId');
  const referer = searchParams.get('referer');
  const queryParams = {
    orderId,
    referer,
  };
  const filteredParams = Object.fromEntries(
    Object.entries(queryParams).filter(([key, value]) => value != null),
  ) as Record<string, string>;
  const params = new URLSearchParams(filteredParams).toString();
  const redirectUrl = redirect
    ? `${params ? `${redirect}?${params}` : redirect}`
    : undefined;
  useEffect(() => {
    const autoLoginAndRedirect = async () => {
      try {
        if (token) {
          const user = await loginWithCustomToken(token);

          if (!user) {
            navigate(PATH_AUTH.login, { replace: true });
          }
        }
      } catch (error) {
        navigate(PATH_AUTH.login, { replace: true });
      }
    };

    autoLoginAndRedirect();
  }, [loginWithCustomToken, token, navigate]);

  useEffect(() => {
    if (isInitialized && !isLoading && data?.onboarded === false) {
      if (isUserRole) {
        navigate(
          redirectUrl && referer && orderId
            ? redirectUrl
            : PATH_DASHBOARD.general.booking.firstSchedule,
        );
      }
      if (data.isTemporary) {
        navigate(QUICK_BOOKING);
      }
      if (isProfessionalRole) {
        navigate(redirectUrl || PATH_DASHBOARD.general.account.general);
      }
    }
  }, [
    isInitialized,
    isLoading,
    navigate,
    data?.onboarded,
    data?.isTemporary,
    redirectUrl,
    referer,
    orderId,
    isUserRole,
    isProfessionalRole,
  ]);

  useEffect(() => {
    if (
      isInitialized &&
      !isLoading &&
      pathname === PATH_DASHBOARD.root &&
      data?.onboarded
    ) {
      isUserRole &&
        navigate(redirectUrl || PATH_DASHBOARD.general.profiles.list);
      isProfessionalRole &&
        navigate(redirectUrl || PATH_DASHBOARD.general.waitingRoom);
    }
  }, [
    isProfessionalRole,
    isUserRole,
    navigate,
    isInitialized,
    isLoading,
    pathname,
    data?.onboarded,
    redirectUrl,
  ]);

  useEffect(() => {
    if (!isAuthenticated && isInitialized && !token) {
      navigate(PATH_AUTH.login, { replace: true });
    }
  }, [isAuthenticated, navigate, isInitialized, pathname, token]);

  useEffect(() => {
    if (pathname !== QUICK_BOOKING) {
      localStorage.removeItem('quickBooking');
    }
  }, []);

  if (!isInitialized || isLoading || !data) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
